import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import User from '~/img/icons/user.svg'
import arrowUp from '~/img/arrow.svg'
import FormLogin from '~/js/components/FormLogin'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import ModalControlCode from '~/js/components/ModalControlCode'
import * as userService from '../../../services/user/user'
import * as iframeService from '~/js/services/iframe'
import * as msCoreService from '../../../services/msCore'
import StatusMessages from '../../../containers/StatusMessages'
import Language from '../../../components/Language'
import { withTranslation } from 'react-i18next'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import ModalZealIdQrCode, { TYPE_AUTH } from '../../../components/ModalZealIdQrCode'
import Error from '~/js/routes/app/SmartCardLogin/Error'
import FaqLinks from '~/js/components/FaqLinks'
import Modal from '../../../components/Modal'
import Loader from '../../../components/Loader'
import LoginDecorationSection from './LoginDecorationSection'
import * as LoginDataProvider from './LoginDataProvider'
import IframeLoginLogo from '../../../components/AppLogo/IframeLoginLogo'

const controlCodeTimeLimit = 90

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.temporaryLoginRouteParams = props.temporaryLinkInfo || {}
    this.isTemporaryLogin = props.temporaryLogin || false

    /**
     * If the path is other than /user/login, like /user/login-smart-id or /user/login-mobile-id or /:language?/sign/bulk/document/:sessionId/:temporarySignLinkId/login
     * and the country is not set that will be used within those route components,
     * then we are redirecting the user to login page to select the country
     */
    if (
      !this.isTemporaryLogin
      && (
        (props.location.pathname !== generatePath(ROUTE_NAMES.USER_LOGIN) && props.location.pathname !== generatePath(ROUTE_NAMES.USER_REGISTER))
        && !props.location?.state?.country
      )
    ) {
      props.history.push(generatePath(ROUTE_NAMES.USER_LOGIN))
    }

    this.loginData = LoginDataProvider.getLoginData(this.isTemporaryLogin, this.temporaryLoginRouteParams)
    const loginRememberMode = !this.isTemporaryLogin && Cookies.get('lgi_1') !== undefined && Cookies.get('lgi_1') !== '{}'

    this.state = {
      loginRememberMode,
      country: 'LT',
      showMe: false,
      isLoading: false,
      isSimplySignLoading: false,
      tabs: this.loginData.authMethods,
      randomQuote: {},
      quotes: this.loginData.quotes,
      modalControlCode: {
        active: false,
        type: undefined,
        controlCode: undefined,
        closeCallback: () => { },
      },
      modalLoginZealId: {
        active: false,
        qrHtml: '',
        isLoading: true,
      },
      redirectTo: undefined,
      lgi1: JSON.parse(Cookies.get('lgi_1') ? Cookies.get('lgi_1') : '{}'),
      lgi2: JSON.parse(Cookies.get('lgi_2') ? Cookies.get('lgi_2') : '{}'),
      showChristmasLogin: false,
      usbErrorMessage: null,
      whiteLabelInfo: null,
    }

    this.loginRememberButton = React.createRef()
    this.onTabClick = this.onTabClick.bind(this)
    this.arrowOperation = this.arrowOperation.bind(this)
    this.showStatusMessage = this.showStatusMessage.bind(this)
    this.onLoginFormSubmit = this.onLoginFormSubmit.bind(this)
    this.userServiceAction = this.userServiceAction.bind(this)
    this.showModalControlCode = this.showModalControlCode.bind(this)
    this.hideModalControlCode = this.hideModalControlCode.bind(this)
    this.initializeZealIdLogin = this.initializeZealIdLogin.bind(this)
    this.onClickRemoveLgiCookie = this.onClickRemoveLgiCookie.bind(this)
    this.onLoginFormSubmitFromCookie = this.onLoginFormSubmitFromCookie.bind(this)
    this.initializeAndShowZealIdModal = this.initializeAndShowZealIdModal.bind(this)
    this.getZealIdToken = this.getZealIdToken.bind(this)
    this.handleOnCountry = this.handleOnCountry.bind(this)
    this.checkSessionStatus = this.checkSessionStatus.bind(this)
    this.handleQuote = this.handleQuote.bind(this)
    this.fetchInstalledTools = this.fetchInstalledTools.bind(this)
    this.toggleChristmasAdd = this.toggleChristmasAdd.bind(this)
    this.initializeSimplySignLogin = this.initializeSimplySignLogin.bind(this)
    this.setRedirectData = this.setRedirectData.bind(this)
    this.updateViewForIframe = this.updateViewForIframe.bind(this)

    this.isTemporaryLogin && Cookies.get('lng') === undefined && this.updateViewForIframe()
  }
  componentDidMount() {
    this.handleQuote()
    this.toggleChristmasAdd()
  }

  updateViewForIframe() {
    iframeService
      .getTemporaryInfoForLogin(this.temporaryLoginRouteParams.temporarySignLinkId, this.temporaryLoginRouteParams.sessionId)
      .then(data => {
        if (data.status === 'ok') {
          this.props.setLangCode(data.data.language)
          this.setState({ whiteLabelInfo: data.data.whiteLabelInfo })
        }
      })
  }

  setRedirectData(data) {
    let redirectTo = generatePath(ROUTE_NAMES.USER_WORKSPACES)
    if (this.isTemporaryLogin) {
      const params = this.temporaryLoginRouteParams
      if (data.temporaryAuthSessionId) {
        params.temporaryAuthSessionId = data.temporaryAuthSessionId
      }
      redirectTo = generatePath(ROUTE_NAMES.TEMPORARY_DOCUMENT_PREVIEW_BULK, params)
    }
    this.setState({ redirectTo })
  }

  toggleChristmasAdd() {
    const christmasStartDate = Date.parse('2022-12-15T00:00:00+02:00')
    const currentDay = Date.now()
    const christmasEndDate = Date.parse('2023-01-01T23:59:59+02:00')

    this.setState( { showChristmasLogin: currentDay >= christmasStartDate && currentDay <= christmasEndDate })
  }

  handleQuote() {
    const { quotes } = this.state
    const randomNumber = Math.floor(Math.random() * quotes.length) + 1 !== 0 ? Math.floor(Math.random() * quotes.length) + 1 : 2
    const randomQuotes = quotes.find(quote => quote.id === randomNumber)
    this.setState({
      randomQuote: randomQuotes
    })
  }

  checkSessionStatus() {
    const { history } = this.props
    this.props.getSessionStatus()
      .then(data => {
        if (data.user.status === 'active' || data.workspace.status === 'active') {
          history.push(generatePath(ROUTE_NAMES.USER_WORKSPACES))
        }
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onTabClick(tabIndex) {
    const { history, setAuthMethod } = this.props
    const tabs = this.state.tabs
    const tab = tabs[tabIndex]

    tabs.forEach(t => t.active = false)
    tab.active = true

    this.setState({ tabs })
    setAuthMethod(tab.id)

    switch (tabIndex) {
    case 0:
    case 1:
    case 2:
      history.push({
        pathname: !this.props.registrationPage ? tab.link : tab.reglink,
        state: { country: this.state.country }
      })
      break
    case 3:
      this.initializeSimplySignLogin()
      break
    case 4:
      this.initializeAndShowZealIdModal()
      break
    case 5:
      this.fetchInstalledTools()
      break
    default:
      break
    }
  }

  arrowOperation() {
    this.setState(prevState => ({ showMe: !prevState.showMe }))
  }

  handleOnCountry(country) {
    this.setState({ country })
  }

  fetchInstalledTools() {
    this.setState({ isLoading: true })

    msCoreService.checkForUsbCompatability()
      .then(({ installData, certData, osCheckData }) => {
        const { country } = this.state
        this.setState({
          redirectTo: {
            pathname: this.isTemporaryLogin ? generatePath(ROUTE_NAMES.TEMPORARY_LOGIN_SMART_CARD, this.temporaryLoginRouteParams) : generatePath(ROUTE_NAMES.USER_LOGIN_SMART_CARD),
            state: {
              installed: installData.success === 'true',
              certificate: certData.find(c => c.type === 'auth'),
              osError: !osCheckData.isWindows,
              country: country,
            },
          }
        })
      })
      .catch((error) => {
        this.setState({ usbErrorMessage:
            msCoreService.handleUsbCheckErrors(error)
        })

        this.stopLoading()
      })
      .finally(() => {
        this.stopLoading()
      })
  }

  initializeSimplySignLogin() {
    this.setState({ isSimplySignLoading: true })
    userService.getSimplySignRedirectInfo()
      .then(data => {
        window.location.href = data.redirect_url
      })
      .catch((err) => {
        this.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
  }

  stopLoading() {
    this.setState({ isLoading: false })

    this.loginRememberButton.current?.classList && this.loginRememberButton.current.classList.remove('loading')
    this.loginRememberButton.current?.removeAttribute('disabled')
  }

  initializeZealIdLogin() {
    userService
      .getQRCode(userService.AUTH_TYPE_ZEALID)
      .then(data => {
        this.setState({
          modalLoginZealId: {
            active: true,
            qrHtml: data.html,
            isLoading: false,
          }
        })
      })
  }

  getZealIdToken(code) {
    userService.getToken(userService.AUTH_TYPE_ZEALID, code)
      .then(data => {
        if (data.status === 'error') {
          this.props.showStatusMessage(SM_TYPE_ERROR, data.message)
        }

        this.props.setSessionId(data.sessionId)
        this.props.setSerialNumber(data.serialNumber)

        setTimeout(() => {
          // this.setState({ redirectTo: generatePath(ROUTE_NAMES.USER_WORKSPACES) })
          this.setRedirectData(data)
        }, 1000)
      })
  }

  showStatusMessage(type, message) {
    const { t } = this.props

    message = message || t('something_went_wrong_try_again')

    this.props.showStatusMessage(type, message)
  }

  showModalControlCode(authType, controlCode, closeCallback) {
    this.setState({
      modalControlCode: {
        active: true,
        authType,
        controlCode,
        closeCallback,
      }
    })
  }

  hideModalControlCode() {
    this.setState({
      modalControlCode: {
        active: false,
      }
    })
  }

  userServiceAction(type, values, { setSubmitting }, lgi) {
    this.props.setAuthInfos(values)
    let canceled = false
    let sessionId = null
    userService
      .initializeLogin(type, values)
      .then(data => {
        sessionId = data.data.session_id
        const statusRequest = userService.pollLoginStatus(type, { token: sessionId }, controlCodeTimeLimit, true)
        this.props.setSessionId(sessionId)
        return statusRequest.promise
      })
      .then(({ data }) => {
        const certificateRequest = userService.pollLoginStatus(type, { token: sessionId }, controlCodeTimeLimit)
        this.showModalControlCode(type, data.control_code, () => {
          canceled = true
          this.hideModalControlCode()
          certificateRequest.cancel()
          if (setSubmitting) {
            setSubmitting(false)
          }
        })
        return certificateRequest.promise
      })
      .then(({ data }) => {
        this.props.setSerialNumber(data.certificate.subject.serial_number)
        this.hideModalControlCode()
        setTimeout(() => {
          this.setRedirectData(data)
        }, 1000)
      })
      .catch(err => {
        if (lgi) {
          this.stopLoading()
          this.setState({ loginRememberMode: false })
        }

        if (canceled) { return }

        this.hideModalControlCode()
        this.showStatusMessage(SM_TYPE_ERROR, err.data?.message || err.message)
        if (setSubmitting) {
          setSubmitting(false)
        }
      })
  }

  onLoginFormSubmit(type, values, formikProps) {
    type === userService.AUTH_TYPE_SMARTCARD ? this.props.handleSmartCardLogin(type, values, formikProps) : this.userServiceAction(type, values, formikProps, false)
  }

  onClickRemoveLgiCookie() {
    if (this.isTemporaryLogin) {
      return
    }

    Cookies.set('lgi_1', '{}')
    Cookies.set('lgi_2', '{}')

    this.setState({
      lgi1: JSON.parse('{}'),
      lgi2: JSON.parse('{}'),
      usbErrorMessage: null,
      loginRememberMode: false,
    })
  }

  onLoginFormSubmitFromCookie() {
    const type = this.state.lgi1.authMethod
    const values = {
      'country': this.state.lgi1.country,
      'personCode': this.state.lgi2.personCode,
      'phoneNumber': this.state.lgi2.phoneNumber,
      'encrypted': true
    }
    const setSubmitting = null

    this.loginRememberButton.current.classList.add('loading')
    this.loginRememberButton.current.setAttribute('disabled', 'disabled')

    if (type === userService.AUTH_TYPE_ZEALID) {
      this.onTabClick(4)
      this.onClickRemoveLgiCookie()
    } else if (type === userService.AUTH_TYPE_SMARTCARD) {
      this.fetchInstalledTools()
    } else if (type === userService.AUTH_TYPE_SIMPLYSIGN){
      this.initializeSimplySignLogin()
    } else {
      this.userServiceAction(type, values, { setSubmitting }, true)
    }
  }

  initializeAndShowZealIdModal() {
    this.initializeZealIdLogin()

    this.setState({
      modalLoginZealId: {
        isLoading: true,
        active: true,
        qrHtml: '',
      }
    })
  }

  render() {
    const { t, btnSubmitText } = this.props
    const {
      tabs,
      showChristmasLogin,
      showMe,
      randomQuote,
      whiteLabelInfo,
    } = this.state
    const title = this.props.title || (this.isTemporaryLogin ? t('temporary_link.login.choose_login_type') : t('user.login.choose_login_type'))
    const currentActiveTab = tabs.find(tab => tab.active)
    const subTitle = this.props.subTitle || (this.isTemporaryLogin ? '' : (
      <>
        {t('user.login.dont_have_account')}
        <Link to={generatePath(ROUTE_NAMES.USER_REGISTER)}> {t('user.login.register')}</Link>
      </>))
    return (
      <div className={`login-form-container ${this.isTemporaryLogin ? 'login-form-container--temporary' : ''}`}>
        <section id="login">
          <div className="login-full-height-container">
            {!this.isTemporaryLogin && <LoginDecorationSection
              showChristmasLogin={showChristmasLogin}
              showChristmasMobile={showMe}
              i18n={this.props.i18n}
              t={t}
              registerMode={this.props.registerMode}
              registrationPage={this.props.registrationPage}
              randomQuote={randomQuote}
            />}
            <div className="rightbar-container">
              <div className="rightbar-container__wrapper">
                {!this.isTemporaryLogin &&
                  <>
                    {/* Top part hide button. The left part of desktop version becomes top part in smaller screen */}
                    <a className='show-hide-button' onClick={this.arrowOperation} style={{ background: showChristmasLogin ? '#E5473E' : '#6AADD9' }}>
                      <img className={!showMe ? 'image-roated' : 'image-roate'} src={arrowUp} alt="" />
                    </a>
                    {/* Cookie login */}
                    {this.state.loginRememberMode && !this.props.loginMode && (
                      <div>
                        <h5 className="ta-center">{t('user.login.welcome_back')}</h5>
                        <div className="login-type">{t('user.login.continue_with_last_account')}</div>
                        <br />
                        <div>
                          <div>
                            <table border="0" className="lgi--margin-0auto">
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="sidebar__user-logo">
                                      <img src={User} alt="User" />
                                    </div>
                                  </td>
                                  <td><b>{this.state.lgi1.fullName}</b></td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="ta-center lgi--padding-30px">
                              <button
                                ref={this.loginRememberButton}
                                onClick={() => this.onLoginFormSubmitFromCookie()}
                                className="btn btn--primary ta-center"
                              >
                                {t('common.continue')}
                              </button>
                            </div>
                            <div className="ta-center lgi--padding-50px">
                              <a
                                href="#"
                                onClick={() => this.onClickRemoveLgiCookie()}
                              >
                                <u>{t('user.login.sign_in_with_another_account')}</u>
                              </a>
                            </div>
                            {this.state.usbErrorMessage &&
                              <Error t={t} errorMessage={this.state.usbErrorMessage} />
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                }
                {(!this.props.loginMode && !this.props.registerMode && !this.state.loginRememberMode) && (
                  <div>
                    <h5 className="ta-center">{title}</h5>
                    <div className="login-type">{subTitle}</div>
                    <div className="login-content">
                      {currentActiveTab &&
                        <div className={'tabs-content__tab' + (currentActiveTab.active ? ' active' : '')}>
                          <FormLogin
                            isLoading={this.state.isLoading}
                            authType={currentActiveTab.authType}
                            onSubmit={this.onLoginFormSubmit}
                            homePage={true}
                            handleOnCountry={this.handleOnCountry}
                            btnSubmitText={btnSubmitText}
                            t={t}
                            location={this.props.location}
                            temporaryLogin={this.props.temporaryLogin}
                            temporaryLinkInfo={this.props.temporaryLinkInfo}
                            countryOptions={this.loginData.countryOptions}
                          />
                        </div>
                      }
                      <p>{t('user.login.login_method')}</p>
                      <div className={'ta-center tabs-content__tab' + (tabs?.[4]?.active ? ' active' : '')}>
                        <ModalZealIdQrCode
                          active={this.state.modalLoginZealId.active}
                          qrHtml={this.state.modalLoginZealId.qrHtml}
                          type={TYPE_AUTH}
                          onCloseClick={() => this.setState({ modalLoginZealId: { active: false } })}
                          onCodeReceive={this.getZealIdToken}
                          isLoading={this.state.modalLoginZealId.isLoading}
                        />
                      </div>
                    </div>
                    <div className='tabs-list__login'>
                      {tabs.map((tab, index) => {
                        if (tab.countryCodes.includes(this.state.country)) {
                          return (
                            <li key={`tab-${tab.id}`} className="login-tabs-new">
                              <button
                                className={`${tab.className} ${tab.SmartCard ? ' active usb' + (this.state.isLoading ? ' btn btn--usb loading' : '') : ' active'}`}
                                onClick={() => this.onTabClick(index)}
                              >
                                <img className={(tab.SmartCard && this.state.isLoading) ? ' d-none' : null} src={tab.image} alt={tab.alt} />
                                <p className={(tab.SmartCard && this.state.isLoading) ? ' d-none' : null} dangerouslySetInnerHTML={{ __html: t(tab.name) }}></p>{tab.number ? <span>{tab.number}</span> : ''}
                              </button>
                            </li>
                          )
                        }
                      })}
                    </div>
                    {this.state.usbErrorMessage &&
                      <Error t={t} errorMessage={this.state.usbErrorMessage} />
                    }
                  </div>
                )}
                {(this.props.loginMode || this.props.registerMode) && (
                  <div>
                    <div className='login-mode'>
                      <Link
                        className="header__page-navigation"
                        to={{ pathname: this.isTemporaryLogin ? generatePath(ROUTE_NAMES.TEMPORARY_LOGIN, this.temporaryLoginRouteParams) : generatePath(this.props.loginMode && ROUTE_NAMES.USER_LOGIN || this.props.registerMode && ROUTE_NAMES.USER_REGISTER) }}
                      >
                        {this.props.title}
                      </Link>
                      <img src={this.props.imgSrc} alt="" />
                    </div>
                    <div className='login-form'>
                      <FormLogin
                        isLoading={this.state.isLoading}
                        authCertificate={this.props.authCertificate}
                        authType={this.props.authType}
                        loginMode={this.props.loginMode}
                        registrationMode={this.props.registerMode}
                        onSubmit={this.onLoginFormSubmit}
                        homePage={true}
                        btnSubmitText={btnSubmitText}
                        t={t}
                        location={this.props.location}
                        temporaryLogin={this.props.temporaryLogin}
                        temporaryLinkInfo={this.props.temporaryLinkInfo}
                        countryOptions={this.loginData.countryOptions}
                      />
                    </div>
                  </div>
                )}
                <FaqLinks
                  pathname={this.props.location.pathname}
                  isTemporaryLogin={this.isTemporaryLogin}
                />
              </div>
            </div>
            <StatusMessages />
          </div >
          {this.isTemporaryLogin && <IframeLoginLogo whiteLabelInfo={whiteLabelInfo} />}
          <Language />
        </section>
        <ModalControlCode
          t={t}
          active={this.state.modalControlCode.active}
          type={this.state.modalControlCode.authType}
          controlCode={this.state.modalControlCode.controlCode}
          timeLimit={controlCodeTimeLimit}
          onCloseClick={this.state.modalControlCode.closeCallback}
        />
        <Modal
          active={this.state.isSimplySignLoading}
          closable={false}
        >
          <Loader loadingText={t('common.loading')} />
        </Modal>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
      </div>
    )
  }
}

export default withTranslation()(Login)

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  authCertificate: PropTypes.object,
  i18n: PropTypes.object,
  getSessionStatus: PropTypes.func,
  showStatusMessage: PropTypes.func,
  t: PropTypes.func,
  setSessionId: PropTypes.func,
  setSerialNumber: PropTypes.func,
  btnSubmitText: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.object,
  loginMode: PropTypes.string,
  registerMode: PropTypes.string,
  authType: PropTypes.string,
  registrationPage: PropTypes.bool,
  imgSrc: PropTypes.string,
  handleSmartCardLogin: PropTypes.func,
  temporaryLogin: PropTypes.bool,
  temporaryLinkInfo: PropTypes.object,
  setLangCode: PropTypes.func,
  setAuthMethod: PropTypes.func,
  setAuthInfos: PropTypes.func,
}
