import React from 'react'
import Select from '~/js/components/Select'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import NavigationBar from '~/js/components/NavigationBar'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import i18next from 'i18next'
import apiClient from '~/js/utils/apiClientDocs'
import { getUserWorkspace } from '~/js/routes/router'
import * as productService from '~/js/services/user/product'
import * as userService from '~/js/services/user/user'
import moment from 'moment'
import StatusMessages from '~/js/containers/StatusMessages'
import Tooltip from '~/js/components/Tooltip'
import Language from '../../../../components/Language/Language'
import CreditCardSerialNumberInput from '../../../../components/CreditCardSerialNumberInput'
import Info from '~/img/icons/info-slick.svg'
import CreditCard from '~/img/icons/credit-card-checkout.svg'
import Visa from '~/img/icons/visa.svg'
import MasterCard from '~/img/icons/master-card.svg'
import AmericanExpress from '~/img/icons/american-express.svg'
import UserGray from '~/img/icons/user-gray.svg'
import Cart from '~/img/icons/cart.svg'
import CheckGreen from '~/img/icons/check-green.svg'
import ErrorSign from '~/img/icons/error-sign.svg'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { ProductFieldObject } from '~/js/dataLayer/models'
import { getUserNamespace } from '~/js/services/user/serviceHelpers'
import _debounce from 'lodash/debounce'
import Modal3DSConfirmation from '~/js/components/Modal3DSConfirmation'
import FieldDebounced from '~/js/components/FieldDebounced'

const NEOWidgetHost = 'https://psd2.neopay.lt'
const today = new Date()

const options = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
]

const customSelectStyles = {
  control: () => ({
    width: 40,
    display: 'flex',
  }),
  container: () => ({
    border: '1px solid #dde0e4',
    borderRadius: '5px',
    padding: '5px 10px',
    position: 'relative',
    maxHeight: '30px',
    maxWidth: '52px',
  }),
  dropdownIndicator: base => ({
    control: baseControl => ({
      ...baseControl,
      '&:hover': {
        borderColor: 'red',
        color: 'red',
      }
    }),
    ...base,
    color: '#0096C7',
    '&:hover': {
      color: '#0087B3',
    }
  }),
  selectContainer: () => ({
    height: '100%',
    padding: '0 10px 10px',
  }),
  singleValue: () => ({
    position: 'absolute',
    width: '20px',
    left: '0',
    top: '2px',
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    left: '22px',
    top: '-4px',
    width: '31px',
  }),
  menu: () => ({
    top: '25px',
    left: '-1px',
    position: 'absolute',
    background: '#fff',
    width: '52px',
    padding: '0 10px 5px 6px',
    border: '1px solid #dde0e4',
    borderRadius: '0 0 5px 5px',
    borderTop: 'transparent',
    zIndex: '1',
  }),
  menuList: () => ({
    maxHeight: 'initial',
    borderTop: '1px solid #dde0e4',
  }),
  option: () => ({
    padding: '8px 11px 8px 4px',
    '&:hover': {
      background: '#0087B3',
      color: '#fff',
      borderRadius: '3px',
    }
  })
}

export default class PricingCheckout extends React.Component {
  stripeSession = null
  constructor(props) {
    super(props)
    const { user, cart } = props
    const product = typeof cart[0] !== 'undefined' ? cart[0].product : null
    const quantity = typeof cart[0] !== 'undefined' ? cart[0].quantity : null
    let paymentType = null

    if (product) {
      paymentType = product.category === 'monthly_subscription_plan' ? 'monthly' : 'annual'
    }

    this.state = {
      isFetchingData: true,
      quantity: quantity ? quantity : 1,
      personType: user.workspaceId ? 'juridical' : 'private',
      products: [],
      product,
      paymentType,
      billingDetails: {
        email: user.email,
        address: user.address,
        country: user.country,
        companyName: user.companyName,
        companyCode: user.companyCode,
        vatNumber: user.vatNumber,
        name: user.name,
        surname: user.surname,
        personalCode: user.personalCode,
        invoiceNotes: '',
        cardHolderName: '',
        cardNumber: '',
        expirationDate: '',
        cvc: '',
      },
      countries: null,
      saveBillingDetails: false,
      subtotal: 0,
      vatPercent: 0,
      vat: 0,
      total: 0,
      isLoading: false,
      isSubmitting: false,
      orderSummaryError: false,
      paymentMethod: 'creditCard',
      card: {
        brand: '',
        last4: '',
        expMonth: '',
        expYear: '',
      },
      coupon: {
        couponId: null,
        percentOff: null,
        code: null,
      },
      isCouponLoading: false,
      changeCard: !this.props.user.stripeCustomerId,
      discountBlockClassName: false,
      redirectTo: null,
      modal3DsConfirmation: {
        active: false,
        src: '',
      },
      isValidVatNumber: null,
      invalidVatMessage: null,
    }

    this.discountCodeField = React.createRef()
    this.discountErrorMessage = React.createRef(null)

    this.onSubmit = this.onSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.loadCardDetails = this.loadCardDetails.bind(this)
    this.calculateValues = this.calculateValues.bind(this)
    this.getCountryOptions = this.getCountryOptions.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.onPaymentTypeChange = this.onPaymentTypeChange.bind(this)
    this.onSubmitDiscountCode = this.onSubmitDiscountCode.bind(this)
    this.updateBillingDetails = this.updateBillingDetails.bind(this)
    this.onChangePaymentMethod = this.onChangePaymentMethod.bind(this)
    this.initializeNeopayClient = this.initializeNeopayClient.bind(this)
    this.onSaveBillingDetailsSelect = this.onSaveBillingDetailsSelect.bind(this)
    this.creditCardExpirationHandler = this.creditCardExpirationHandler.bind(this)
    this.initializePaymentTransaction = this.initializePaymentTransaction.bind(this)
    this.setBillingCountryIfNull = this.setBillingCountryIfNull.bind(this)
    this.onChangeBillingDetailsCountry = this.onChangeBillingDetailsCountry.bind(this)
    this.showIframe = this.showIframe.bind(this)
    this.onModalActivityCompletion = this.onModalActivityCompletion.bind(this)
    this.on3dsModalClose = this.on3dsModalClose.bind(this)
    this.onVatNumberChange = this.onVatNumberChange.bind(this)
    this.calculateValuesRequest = null

    this.fetchData()
    this.loadCardDetails()
  }

  handleClickOutside(event) {
    if (this.discountCodeField.current && !this.discountCodeField.current.contains(event.target) && this.discountErrorMessage.current) {
      this.setState({ coupon: { invalid: false } })
    }
  }

  onChangePaymentMethod(e) {
    this.setState({ paymentMethod: e.currentTarget.value })
  }

  handleInputChange(e) {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  fetchData() {
    Promise.all([
      userService.getBillingDetails(),
      productService.getPlanProducts(),
      apiClient.get('/v1/country'),
    ])
      .then(([{ data: billingDetails }, { data: products }, { data: { data: countries } }]) => {
        this.setState({
          isFetchingData: false,
          billingDetails: {
            ...billingDetails,
            personalCode: billingDetails.personCode,
            cardHolderName: '',
            cardNumber: '',
            expirationDate: '',
            cvc: '',
          },
          countries,
          products,
        })
      })
      .then(() => this.setBillingCountryIfNull())
      .then(() => {
        if (!this.state.product) {
          return Promise.resolve()
        }

        return this.calculateValues(this.state.product.id, this.state.quantity, this.state.billingDetails.country, this.state.billingDetails.vatNumber)
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setBillingCountryIfNull() {
    if (!this.state.billingDetails.country) {
      const countryOptions = this.getCountryOptions()
      this.setState(prevState => ({
        billingDetails: {
          ...prevState.billingDetails,
          country: countryOptions[130].value
        }
      }))
    }
  }

  handleChange(quantity) {
    this.setState({
      quantity: quantity
    })
  }

  onPaymentTypeChange(event) {
    this.setState({
      paymentType: event.currentTarget.value
    })
  }

  getExpirationDate(paymentType) {
    const todayMoment = moment(today)

    if (paymentType === 'annual') {
      todayMoment.add(1, 'years')
    } else {
      todayMoment.add(1, 'months')
    }

    return todayMoment.format('YYYY-MM-DD')
  }

  calculateValues(productId, quantity, country, vatNumber) {
    typeof this.calculateValuesRequest?.cancel === 'function' && this.calculateValuesRequest.cancel()

    const controller = new AbortController()
    const cancelTokenSource = apiClient.defaults.cancelTokenSource()
    let isPending = true

    this.setState({ isLoading: true })
    this.calculateValuesRequest = {
      promise: apiClient
        .post(`v1/${getUserWorkspace()}/checkout/calculate-values`, {
          productId: productId,
          quantity: quantity,
          percentOff: this.state.coupon.percentOff,
          country,
          vatNumber: this.state.personType === 'juridical' ? vatNumber : null
        }, {
          cancelToken: cancelTokenSource.token,
          signal: controller.signal
        })
        .then(data => this.setState({
          subtotal: data.data.subtotal,
          vatPercent: data.data.vatPercent,
          vat: data.data.vat,
          total: data.data.total,
          discountedSubtotal: data.data.discountedSubtotal,
          isValidVatNumber: data.data.isValidVatNumber,
          invalidVatMessage: data.data.message,
        }, () => this.setState({ orderSummaryError: false }, () => isPending = false)))
        .catch(err => this.calculateValuesRequest.isPending() || this.setState({ orderSummaryError: true }, () => this.props.showStatusMessage(SM_TYPE_ERROR, err.message)))
        .finally(() => this.calculateValuesRequest.isPending() || this.setState({ isCouponLoading: false, isLoading: false })),
      cancel: (reason) => {
        typeof cancelTokenSource === 'object' && typeof cancelTokenSource.cancel === 'function' ? cancelTokenSource.cancel(reason) : (typeof controller === 'object' && typeof controller.abort === 'function' ? controller.abort(reason) : null)
      },
      isPending: () => isPending
    }
    return this.calculateValuesRequest
  }

  updateBillingDetails(params) {
    let promise = Promise.resolve()

    if (this.state.saveBillingDetails) {
      const { t, showStatusMessage } = this.props

      promise = userService
        .updateBillingDetails({
          fullName: params.fullName,
          email: params.email,
          address: params.address,
          country: params.country,
          companyName: params.companyName,
          companyCode: params.companyCode,
          vatNumber: params.vatNumber,
          invoiceNotes: params.invoiceNotes
        })
        .then(() => {
          showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.settings_were_successfully_changed'))
        })
        .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
    }

    return promise
  }

  initializePaymentTransaction(productId, params) {
    return apiClient
      .post(`v1/${getUserWorkspace()}/neopay/init-payment/subscription/${productId}`, {
        params,
        paymentMethod: this.state.paymentMethod,
        quantity: this.state.quantity,
        coupon: this.state.coupon.couponId,
      })
      .then(this.initializeNeopayClient)
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onSubmit(params) {
    this.setState({ isSubmitting: true })
    const { product, discountedSubtotal, subtotal, coupon, changeCard } = this.state
    const { showStatusMessage, pushGTagBeginCheckout, pushGTagAddPaymentInfo } = this.props
    pushGTagBeginCheckout(product, subtotal, discountedSubtotal, coupon)

    if (this.state.paymentMethod === 'creditCard') {
      if (changeCard) {
        pushGTagAddPaymentInfo(product, subtotal, discountedSubtotal, coupon)
      }

      this.updateBillingDetails(params)
        .then(() => apiClient
          .post(`v1/${getUserWorkspace()}/stripe/create-checkout-session`, {
            productId: this.state.product.id,
            params: params,
            quantity: this.state.quantity,
            mode: this.state.product.category === 'signature' ? 'payment' : 'subscription',
            customerEmail: params.email,
            stripeCustomerId: this.props.user.stripeCustomerId,
            coupon: this.state.coupon.couponId,
            amount: this.state.total,
            paymentMethod: this.state.paymentMethod
          }))
        .then(({ data: { data } }) => {
          this.stripeSession = data.session
          this.pushGTagPurchase(data.session)
          this.props.clearCart()

          this.showIframe(data.paymentIntent)
          return data
        })
        .then(({ session, paymentIntent }) => !paymentIntent.activate3ds && this.startPollingSessionStatusTimer(session))
        .catch(err => {
          this.setState({ isSubmitting: false })
          showStatusMessage(SM_TYPE_ERROR, err.message)
        })
    } else {
      this.initializePaymentTransaction(this.state.product.id, params)
    }
  }

  startPollingSessionStatusTimer(session) {
    const { product, discountedSubtotal, subtotal, coupon, vat } = this.state
    this.pollSessionStatus(session.id).promise
      .then(({ data }) => {
        this.props.pushGTagPurchaseSuccess(product, subtotal, discountedSubtotal, coupon, vat, data.paymentIntentId, this.props.user)
        this.setState({ redirectTo: data.redirectTo, isSubmitting: false })
      })
      .catch(err => {
        this.setState({ isSubmitting: false })
        this.props.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
  }

  showIframe(paymentIntent) {
    this.setState({
      modal3DsConfirmation: {
        active: paymentIntent?.activate3ds,
        src: paymentIntent?.iframeModal3dsUrl
      }
    })
  }

  pollSessionStatus(stripeSessionId) {
    let canceled = false
    const timeLimitMs = 120 * 1000
    const startTime = new Date()
    const url = `v1/${getUserNamespace()}/stripe/session/${stripeSessionId}/status`
    const promise = new Promise((resolve, reject) => {
      const requestStatus = () => {
        if (new Date() - startTime > timeLimitMs) {
          return reject(new Error('Timeout while payment was being processed.'))
        }
        apiClient
          .get(url)
          .then(({ data }) => {
            if (canceled) { return reject({ status: 'failed', message: this.props.t('checkout.payment_canceled') }) }
            if (data.status === 'ok') { return resolve(data) }
            if (data.status === 'failed') { return reject(data) }

            requestStatusDebounced()
          })
          .catch(reject)
      }
      const requestStatusDebounced = _debounce(requestStatus, 1000)

      requestStatusDebounced()
    })

    const cancel = () => canceled = true

    return { promise, cancel }
  }

  pushGTagPurchase(session) {
    const { total, product } = this.state
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'eec.purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: session.id,
            revenue: total,
          },
          products: [ProductFieldObject.fromProduct(product)]
        }
      }
    })
  }

  onSubmitDiscountCode(discountCode) {
    this.setState({ isCouponLoading: true })

    if (!discountCode) {
      this.setState({ coupon: { invalid: true } })

      return
    }

    apiClient
      .post(`v1/${getUserWorkspace()}/stripe/retrieve-discount-code`, {
        discountCode: discountCode.toUpperCase()
      })
      .then(({ data }) => this.setState({
        coupon: {
          couponId: data.data.couponId,
          percentOff: data.data.percentOff,
          code: discountCode
        }
      }))
      .catch(err => {
        if (err.response.status !== 400) {
          this.props.showStatusMessage(SM_TYPE_ERROR, err.message)
        }

        this.setState({ coupon: { invalid: true } })
      })
      .finally(() => this.calculateValues(this.state.product.id, this.state.quantity, this.state.billingDetails.country, this.state.billingDetails.vatNumber))
  }

  loadCardDetails() {
    if (this.props.user.stripeCustomerId) {
      apiClient
        .get(`v1/${getUserWorkspace()}/stripe/card-details/${this.props.user.stripeCustomerId}`)
        .then(({ data }) => this.setState({
          card: {
            brand: data.data.cardBrand,
            last4: data.data.last4,
            expMonth: data.data.expMonth,
            expYear: data.data.expYear,
          }
        }))
    }
  }

  initializeNeopayClient(res) {
    const token = res.data.token

    /* global NEOWidget */
    NEOWidget.initialize(
      NEOWidgetHost,
      token,
      {
        'default_country': this.props.user.country,
        'default_language': i18next.language.toUpperCase(),
        'client_redirect_url': `https://${window.location.hostname}/neopay/user-callback`
      }
    )
  }

  isNeopayScriptLoaded(url) {
    if (!url) { url = `${NEOWidgetHost}/widget.js` }
    const scripts = document.getElementsByTagName('script')
    for (let i = scripts.length; i--;) {
      if (scripts[i].src === url) { return true }
    }
    return false
  }

  componentDidMount() {
    if (!this.isNeopayScriptLoaded()) {
      const script = document.createElement('script')
      script.src = `${NEOWidgetHost}/widget.js`
      script.async = true
      document.body.appendChild(script)
    }

    window.addEventListener('scroll', this.handleScroll)
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    const { products, paymentType, quantity } = this.state
    let currentProduct = this.state.product

    if (paymentType !== prevState.paymentType) {
      products.forEach(product => {
        if (
          paymentType === 'monthly' && product.category === 'monthly_subscription_plan' && product.name === this.state.product.name ||
          paymentType === 'annual' && product.category === 'annual_subscription_plan' && product.name === this.state.product.name
        ) {
          this.setState({ product })
          currentProduct = product

          if (paymentType === 'monthly') {
            this.setState({ paymentMethod: 'creditCard' })
          }
        }
      })
      this.calculateValues(currentProduct.id, this.state.quantity, this.state.billingDetails.country, this.state.billingDetails.vatNumber)
    }

    if (quantity !== prevState.quantity) {
      this.calculateValues(this.state.product.id, quantity, this.state.billingDetails.country, this.state.billingDetails.vatNumber)
    }
  }

  handleScroll() {
    window.pageYOffset > 217
      ? this.setState({ discountBlockClassName: true })
      : this.setState({ discountBlockClassName: false })
  }

  onSaveBillingDetailsSelect() {
    this.setState({ saveBillingDetails: !this.state.saveBillingDetails })
  }

  getCountryOptions() {
    const { countries } = this.state
    const { i18n } = this.props
    const enCountries = countries === null ? {} : countries.en
    const countryLang = typeof countries[i18n.language] !== 'undefined' ? i18n.language : 'en'

    return Object.keys(enCountries).map(c => ({
      value: c,
      label: countries[countryLang][c],
    }))
  }

  creditCardExpirationHandler(event) {
    const code = event.keyCode
    const allowedKeys = [8]
    if (allowedKeys.indexOf(code) !== -1) {
      return
    }

    event.target.value = event.target.value.replace(
      /^([1-9]\/|[2-9])$/g, '0$1/'
    ).replace(
      /^(0[1-9]|1[0-2])$/g, '$1/'
    ).replace(
      /^([0-1])([3-9])$/g, '0$1/$2'
    ).replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2'
    ).replace(
      /^([0]+)\/|[0]+$/g, '0'
    ).replace(
      /[^\d/]|^[/]*$/g, ''
    ).replace(
      /\/\//g, '/'
    )
  }

  onChangeBillingDetailsCountry(selectedCountry) {
    this.setState(prevState => ({
      billingDetails: {
        ...prevState.billingDetails,
        country: selectedCountry
      }
    }), () => this.calculateValues(this.state.product.id, this.state.quantity, this.state.billingDetails.country, this.state.billingDetails.vatNumber))
  }

  onModalActivityCompletion() {
    this.setState({
      modal3DsConfirmation: {
        active: false,
        src: ''
      }
    }, () => this.startPollingSessionStatusTimer(this.stripeSession))
  }

  on3dsModalClose() {
    this.setState({
      modal3DsConfirmation: { active: false }, isSubmitting: false
    }, () => this.props.showStatusMessage(SM_TYPE_ERROR, this.props.t('checkout.payment_canceled')))
  }

  onVatNumberChange(value) {
    this.setState(prevState => ({
      ...prevState,
      billingDetails: {
        ...prevState.billingDetails,
        vatNumber: value
      }
    }))
    this.calculateValues(this.state.product.id, this.state.quantity, this.state.billingDetails.country, this.state.billingDetails.vatNumber)
      .promise
      .then(() => this.setState({ isSubmitting: false }))
  }

  render() {
    const { t, user } = this.props
    const { product, paymentMethod, personType, paymentType, billingDetails, orderSummaryError, isLoading, isSubmitting, redirectTo, isCouponLoading, coupon } = this.state
    const initialValues = { ...billingDetails, personType }
    initialValues.companyName = initialValues.companyName || ''
    initialValues.companyCode = initialValues.companyCode || ''
    initialValues.email = initialValues.email || user.companyEmailNotConfirmed
    initialValues.discountCode = initialValues.discountCode || ''
    let validationSchema

    if (this.state.isFetchingData) {
      return null
    }

    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }

    const countryOptions = this.getCountryOptions()
    const defaultCountry = countryOptions.find(c => c.value === billingDetails.country)
    const countryNames = countryOptions.sort((a, b) => a.label.localeCompare(b.label))

    if (this.state.personType === 'juridical') {
      validationSchema = Yup.object().shape({
        email: Yup.string()
          .nullable()
          .required(t('validation.field_is_required'))
          .email(t('validation.value_is_not_a_valid_email')),
        companyName: Yup.string()
          .required(t('validation.field_is_required')),
        companyCode: Yup.string()
          .required(t('validation.field_is_required'))
          .matches(/^\d+(-\d+)*$/, t('validation.input_number_validation')),
        cardHolderName: (!this.props.user.stripeCustomerId || this.state.changeCard) && this.state.paymentMethod === 'creditCard' && Yup.string()
          .required(t('validation.field_is_required')),
        cardNumber: (!this.props.user.stripeCustomerId || this.state.changeCard) && this.state.paymentMethod === 'creditCard' && Yup.string()
          .required(t('validation.field_is_required'))
          .min(18, t('validation.card_number_should_contain_not_less_than_15_digits'))
          .max(24, t('validation.card_number_should_contain_not_more_than_19_digits')),
        expirationDate: (!this.props.user.stripeCustomerId || this.state.changeCard) && this.state.paymentMethod === 'creditCard' && Yup.string()
          .required(t('validation.field_is_required')),
        cvc: (!this.props.user.stripeCustomerId || this.state.changeCard) && this.state.paymentMethod === 'creditCard' && Yup.string()
          .required(t('validation.field_is_required'))
          .length(3, t('validation.cvc_should_be_3_digits_length')),
        address: Yup.string().nullable().required(t('validation.field_is_required')),
        country: Yup.string().nullable(),
      })
    } else {
      validationSchema = Yup.object().shape({
        email: Yup.string()
          .required(t('validation.field_is_required'))
          .email(t('validation.value_is_not_a_valid_email'))
          .nullable(),
        fullName: Yup.string()
          .required(t('validation.field_is_required')),
        personalCode: Yup.string()
          .required(t('validation.field_is_required'))
          .when(['country'], {
            is: country => country.toLowerCase() === 'lv',
            then: personalCode => personalCode.matches(/^\d+(-\d+)$/, t('validation.input_number_validation')),
            otherwise: personalCode => personalCode.matches(/^\d+$/, t('validation.input_number_validation'))
          }),
        cardHolderName: (!this.props.user.stripeCustomerId || this.state.changeCard) && this.state.paymentMethod === 'creditCard' && Yup.string()
          .required(t('validation.field_is_required')),
        cardNumber: (!this.props.user.stripeCustomerId || this.state.changeCard) && this.state.paymentMethod === 'creditCard' && Yup.string()
          .required(t('validation.field_is_required'))
          .min(18, t('validation.card_number_should_contain_not_less_than_15_digits'))
          .max(24, t('validation.card_number_should_contain_not_more_than_19_digits')),
        expirationDate: (!this.props.user.stripeCustomerId || this.state.changeCard) && this.state.paymentMethod === 'creditCard' && Yup.string()
          .required(t('validation.field_is_required')),
        cvc: (!this.props.user.stripeCustomerId || this.state.changeCard) && this.state.paymentMethod === 'creditCard' && Yup.string()
          .required(t('validation.field_is_required'))
          .length(3, t('validation.cvc_should_be_3_digits_length')),
        address: Yup.string().nullable().required(t('validation.field_is_required')),
        country: Yup.string().nullable(),
      })
    }

    return (
      <main className="checkout">
        <NavigationBar
          t={t}
          user={user}
          personType={this.state.personType}
          history={this.props.history}
          path={this.props.match.path}
        />
        <div className="grid grid--checkout">
          <div id="header" className="checkout__header">
            <h1 className="page-title">{(product && product.category === 'signature') ? t('user.pricing.eSignature_package') : t('user.pricing.subscription')}</h1>
            <Language />
          </div>
          {product && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={values => this.onSubmit(values)}
              validateOnChange={false}
              validateOnBlur={false}
              validate={() => {
                const errors = {}
                if (this.state.invalidVatMessage) {
                  errors.vatNumber = this.state.invalidVatMessage
                }
                return errors
              }}
            >
              {props => (
                <Form className="row">
                  <div className="col lg-6-12">
                    <div className="wrapped-section">
                      <div className="wrapped-section__headline">
                        <h2 className="checkout__header">
                          <img src={Info} alt="Info" />
                          {t('user.pricing.ordering_information')}
                        </h2>
                      </div>
                      <div className="checkout__section">
                        <ul>
                          <li>
                            <div className="info-details__label">
                              <label>
                                {t(product.category !== 'signature' ?
                                  'user.pricing.plan_name' :
                                  'user.pricing.product_name'
                                )}
                              </label>
                            </div>
                            <div className="info-details__info"><p>{t(`user.pricing.${product.name}`)}</p>
                            </div>
                          </li>
                          {product.category !== 'signature' && (
                            <>
                              <li>
                                <div className="info-details__label"><label>{t('user.pricing.expiration_date')}</label>
                                </div>
                                <div className="info-details__info"><p>{this.getExpirationDate(paymentType)}</p></div>
                              </li>
                              {product.name === 'teams' && (
                                <li>
                                  <div className="info-details__label">
                                    <label>{t('user.pricing.number_of_users')}</label>
                                  </div>
                                  <div className="info-details__info">
                                    <Select
                                      options={options}
                                      components={{ IndicatorSeparator: () => null }}
                                      defaultValue={[options[0]]}
                                      styles={customSelectStyles}
                                      onChange={e => this.setState({ quantity: e.value })}
                                      isSearchable={false}
                                    />
                                  </div>
                                </li>
                              )
                              }
                              <li className="checkout__pick-subscription">
                                <div className="info-details__label"><label>{t('user.pricing.payment_type')}</label></div>
                                <div className="info-details__info">
                                  <div className="form-group__radio">
                                    <input
                                      className="popup-radio-value"
                                      type="radio"
                                      name="paymentType"
                                      id="radioPayment1"
                                      defaultChecked={paymentType === 'monthly'}
                                      defaultValue="monthly"
                                      onChange={this.onPaymentTypeChange}
                                    />
                                    <label htmlFor="radioPayment1">{t('user.pricing.monthly')}</label>
                                    {paymentType === 'monthly' && (
                                      <div
                                        className="checkout__prices-monthly"
                                      >{product.monthlyPrice} {t('user.pricing.vat_month')}
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-group__radio">
                                    <input
                                      className="popup-radio-value"
                                      type="radio"
                                      name="paymentType"
                                      id="radioPayment2"
                                      defaultChecked={paymentType === 'annual'}
                                      defaultValue="annual"
                                      onChange={this.onPaymentTypeChange}
                                    />
                                    <label htmlFor="radioPayment2">{t('user.pricing.annual')}</label>
                                    {paymentType === 'annual' && (
                                      <div className={i18next.language === 'en' ? 'checkout__prices-annual' : 'checkout__prices-annual price__anunal_lt'}>
                                        {product.monthlyPlanPriceAnnually} {t('user.pricing.vat_annual')}
                                        {!coupon.couponId && (
                                          <span>{t('user.pricing.save_up_to')}</span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="wrapped-section">
                      <div className="wrapped-section__headline">
                        <h2 className="checkout__header">
                          <img src={CreditCard} alt="Credit Card" />
                          {t('user.pricing.payment_type')}
                        </h2>
                      </div>
                      <div className="info-details">
                        <ul className="checkout__form">
                          <li className="checkout__payment--method">
                            <p>{t('user.pricing.chose_payment_method')}</p>
                            <div className="info-details__info">
                              <div className="form-group__radio">
                                <Field
                                  className="popup-radio-value"
                                  type="radio"
                                  name="paymentMethod"
                                  id="paymentMethod1"
                                  value="creditCard"
                                  checked={this.state.paymentMethod === 'creditCard'}
                                  onChange={this.onChangePaymentMethod}
                                />
                                <label htmlFor="paymentMethod1">{t('user.pricing.pay_with_card')}</label>
                              </div>
                              {this.state.product.category === 'annual_subscription_plan' && (
                                <div className="form-group__radio">
                                  <Field
                                    className="popup-radio-value"
                                    type="radio"
                                    name="paymentMethod"
                                    id="paymentMethod2"
                                    value="bankTransfer"
                                    checked={paymentMethod === 'bankTransfer'}
                                    onChange={this.onChangePaymentMethod}
                                  />
                                  <label htmlFor="paymentMethod2">{t('user.pricing.way_with_bank_transfer')}</label>
                                </div>
                              )}
                            </div>
                          </li>
                          {
                            paymentMethod === 'creditCard'
                              ? (
                                this.props.user.stripeCustomerId && !this.state.changeCard ?
                                  <>
                                    <li>
                                      <div className="info-details__label">
                                        <label>{t('user.pricing.billing_information_card_number')}</label>
                                      </div>
                                      <div className="info-details__info">
                                        <p>**** **** **** {this.state.card.last4}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="info-details__label">
                                        <label>{t('user.pricing.billing_information_valid_until')}</label>
                                      </div>
                                      <div className="info-details__info">
                                        <p>{this.state.card.expMonth + '/' + this.state.card.expYear}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="info-details__label">
                                        <label>{t('user.pricing.billing_information_brand')}</label>
                                      </div>
                                      <div className="info-details__info">
                                        <p>{this.state.card.brand}</p>
                                      </div>
                                    </li>
                                    <button
                                      className="btn btn--primary"
                                      type="button"
                                      onClick={() => this.setState({ changeCard: true })}
                                    >
                                      {t('user.pricing.billing_information_change_card')}
                                    </button>
                                  </> :
                                  <>
                                    <li>
                                      <div className="info-details__label">
                                        <label>{t('user.pricing.billing_information_name_surname')}</label>
                                      </div>
                                      <div className="info-details__info">
                                        <div
                                          className={'form-group form-group--fixed-size' + (props.errors.cardHolderName && props.touched.cardHolderName ? ' form-group--error' : '')}
                                        >
                                          <Field className="form-group__input" type="text" name="cardHolderName" />
                                          <ErrorMessage className="form-group__error" name="cardHolderName" component="span" />
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="info-details__label">
                                        <label>{t('user.pricing.billing_information_card_number')}</label>
                                      </div>
                                      <div className="info-details__info">
                                        <div
                                          className={'form-group form-group--fixed-size' + (props.errors.cardNumber && props.touched.cardNumber ? ' form-group--error' : '')}
                                        >
                                          <CreditCardSerialNumberInput name="cardNumber" />
                                          <ErrorMessage className="form-group__error" name="cardNumber" component="span" />
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="info-details__label">
                                        <label>{t('user.pricing.billing_information_valid_until')}</label>
                                      </div>
                                      <div className="info-details__info">
                                        <div
                                          className={'form-group form-group--fixed-size' + (props.errors.expirationDate && props.touched.expirationDate ? ' form-group--error' : '')}
                                        >
                                          <Field
                                            className="form-group__input"
                                            type="text" name="expirationDate"
                                            maxLength="5"
                                            onKeyUp={this.creditCardExpirationHandler}
                                          />
                                          <ErrorMessage className="form-group__error" name="expirationDate" component="span" />
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="info-details__label">
                                        <label>{t('user.pricing.billing_information_cvc')}</label>
                                      </div>
                                      <div className="info-details__info checkout__cvc">
                                        <div
                                          className={'form-group form-group--fixed-size' + (props.errors.cvc && props.touched.cvc ? ' form-group--error' : '')}
                                        >
                                          <Field className="form-group__input" type="text" name="cvc" maxLength="3" onBlur={this.onChangeCardDetails} />
                                          <ErrorMessage className="form-group__error" name="cvc" component="span" />
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="info-details__label">
                                        <label>{t('user.pricing.billing_information_possible_payments')}</label>
                                        <div className="checkout__possible-payments">
                                          <img src={Visa} alt="Visa" />
                                          <img src={MasterCard} alt="Master Card" />
                                          <img src={AmericanExpress} alt="American Express" />
                                        </div>
                                      </div>
                                    </li>
                                  </>
                              )
                              : ''
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="wrapped-section">
                      <div className="wrapped-section__headline">
                        <h2 className="checkout__header">
                          <img src={UserGray} alt="User Gray" />
                          {t('user.pricing.billing_information')}
                        </h2>
                      </div>
                      <div className="info-details">
                        <ul className="checkout__form">
                          <li>
                            <div className="info-details__info">
                              <div className="form-group__radio">
                                <Field
                                  className="popup-radio-value"
                                  type="radio"
                                  name="personType"
                                  id="radio1"
                                  value="private"
                                  onChange={e => {
                                    this.setState({ personType: e.target.value }, () => {
                                      this.calculateValues(this.state.product.id, this.state.quantity, this.state.billingDetails.country, this.state.billingDetails.vatNumber)
                                    })
                                    return props.handleChange(e)
                                  }}
                                />
                                <label htmlFor="radio1">{t('user.pricing.private')}</label>
                              </div>
                              <div className="form-group__radio">
                                <Field
                                  className="popup-radio-value"
                                  type="radio"
                                  name="personType"
                                  id="radio2"
                                  value="juridical"
                                  onChange={e => {
                                    this.setState({ personType: e.target.value }, () => {
                                      this.calculateValues(this.state.product.id, this.state.quantity, this.state.billingDetails.country, this.state.billingDetails.vatNumber)
                                    })
                                    return props.handleChange(e)
                                  }}
                                />
                                <label htmlFor="radio2">{t('user.pricing.juridical')}</label>
                              </div>
                            </div>
                          </li>
                          {props.values.personType === 'juridical' ?
                            <li>
                              <div className="info-details__label">
                                <label>* {t('user.pricing.company_name')}</label>
                              </div>
                              <div className="info-details__info">
                                <div
                                  className={'form-group form-group--fixed-size' + (props.errors.companyName && props.touched.companyName ? ' form-group--error' : '')}
                                >
                                  <Field className="form-group__input" type="text" name="companyName" />
                                  <ErrorMessage className="form-group__error" name="companyName" component="span" />
                                </div>
                              </div>
                            </li>
                            :
                            <li>
                              <div className="info-details__label">
                                <label>* {t('common.fullName')}</label>
                              </div>
                              <div className="info-details__info">
                                <div
                                  className={'form-group form-group--fixed-size' + (props.errors.fullName && props.touched.fullName ? ' form-group--error' : '')}
                                >
                                  <Field className="form-group__input" type="text" name="fullName" />
                                  <ErrorMessage className="form-group__error" name="fullName" component="span" />
                                </div>
                              </div>
                            </li>
                          }
                          {props.values.personType === 'juridical' ?
                            <React.Fragment>
                              <li>
                                <div className="info-details__label">
                                  <label>* {t('user.pricing.company_code')}</label>
                                </div>
                                <div className="info-details__info">
                                  <div
                                    className={'form-group form-group--fixed-size' + (props.errors.companyCode && props.touched.companyCode ? ' form-group--error' : '')}
                                  >
                                    <Field className="form-group__input" type="text" name="companyCode" />
                                    <ErrorMessage className="form-group__error" name="companyCode" component="span" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="info-details__label">
                                  <label>{t('user.pricing.vat_number')}</label>
                                </div>
                                <div className="info-details__info">
                                  <div
                                    className={'form-group form-group--fixed-size' + (props.errors.vatNumber && props.touched.vatNumber ? ' form-group--error' : '')}
                                  >
                                    <FieldDebounced
                                      fieldName='vatNumber'
                                      onEachKeyPress={() => this.setState({ isSubmitting: true })}
                                      onChange={this.onVatNumberChange}
                                      formikProps={props}
                                      wait={1500}
                                    />
                                    <ErrorMessage className="form-group__error" name='vatNumber' component="span" />
                                  </div>
                                </div>
                              </li>
                            </React.Fragment>
                            :
                            <li>
                              <div className="info-details__label">
                                <label>* {t('user.pricing.personal_code')}</label>
                              </div>
                              <div className="info-details__info">
                                <div
                                  className={'form-group form-group--fixed-size' + (props.errors.personalCode && props.touched.personalCode ? ' form-group--error' : '')}
                                >
                                  <Field className="form-group__input" type="text" name="personalCode" />
                                  <ErrorMessage className="form-group__error" name="personalCode" component="span" />
                                </div>
                              </div>
                            </li>
                          }
                          <li>
                            <div className="info-details__label">
                              <label>* {t('user.pricing.email')}
                                <Tooltip text={t('user.pricing.payments_information_will_be_sent_to_this_email')} inline={true}>
                                  <span className="info-icon-grey" />
                                </Tooltip>
                              </label>
                            </div>
                            <div className="info-details__info">
                              <div
                                className={'form-group form-group--fixed-size' + (props.errors.email && props.touched.email ? ' form-group--error' : '')}
                              >
                                <Field className="form-group__input" type="mail" name="email" />
                                <ErrorMessage className="form-group__error" name="email" component="span" />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="info-details__label">
                              <label>* {t('user.pricing.address')}</label>
                            </div>
                            <div className="info-details__info">
                              <div
                                className={'form-group form-group--fixed-size' + (props.errors.address && props.touched.address ? ' form-group--error' : '')}
                              >
                                <Field className="form-group__input" type="text" name="address" />
                                <ErrorMessage className="form-group__error" name="address" component="span" />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="info-details__label">
                              <label>* {t('common.country')}</label>
                            </div>
                            <div className="info-details__info">
                              <div
                                className={'form-group form-group--fixed-size form-group--select' + (props.errors.country && props.touched.country ? ' form-group--error' : '')}
                              >
                                <Select
                                  name="country"
                                  options={countryNames}
                                  defaultValue={defaultCountry}
                                  value={defaultCountry}
                                  placeholder={t('common.select')}
                                  onChange={option => (props.setFieldValue('country', option.value) && this.onChangeBillingDetailsCountry(option.value))}
                                  onBlur={props.handleBlur}
                                />
                                <ErrorMessage className="form-group__error" name="country" component="span" />
                              </div>
                            </div>
                          </li>
                        </ul>

                        <div className="form-group__checkbox">
                          <input className="checkbox__document" type="checkbox" id="add-to-contacts" checked={this.state.saveBillingDetails} onChange={this.onSaveBillingDetailsSelect} />
                          <label htmlFor="add-to-contacts">{t('user.pricing.save_billing_details')}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`col lg-6-12 ${this.state.discountBlockClassName ? ' sticky-top' : ''}`}>
                    <div className="order-summary wrapped-section">
                      <div className="wrapped-section__headline">
                        <h2 className="checkout__header pacific-blue">
                          <img src={Cart} alt="Cart" />
                          {t('user.pricing.order_summary')}
                        </h2>
                      </div>
                      <div className="checkout__section">
                        <ul>
                          <li>
                            <div className="info-details__label">
                              <label>{t('user.pricing.subtotal')}</label>
                            </div>
                            <div className="info-details__info">
                              <p>{this.state.subtotal}</p>
                            </div>
                          </li>
                          {this.state.discountedSubtotal && (
                            <li>
                              <div className="info-details__label">
                                <label>{t('user.pricing.price_after_discount')}</label>
                              </div>
                              <div className="info-details__info">
                                <p>{this.state.discountedSubtotal}</p>
                              </div>
                            </li>
                          )}
                          <li>
                            <div className="info-details__label">
                              <label>{t('user.pricing.vat')} {this.state.vatPercent ? `(${this.state.vatPercent}%)` : ''}</label>
                            </div>
                            <div className="info-details__info">
                              <p>{this.state.vat}</p>
                            </div>
                          </li>
                          <li>
                            <div className="info-details__label">
                              <label>{t('user.pricing.total_incl_vat')}</label>
                            </div>
                            <div className="info-details__info">
                              <p>{this.state.total}</p>
                            </div>
                          </li>
                          {(product.categories.includes('subscription_plan')) && (
                            <li className="checkout__discount">
                              <div className="info-details__label">
                                <label>{t('user.pricing.discount_code')}</label>
                              </div>
                              <div className="info-details__info">
                                <div
                                  className={'form-group mr-0 form-group--fixed-size'
                                    + (props.errors.discountCode && props.touched.discountCode ? ' form-group--error' : '')
                                    + (this.state.coupon.invalid ? ' invalid' : '')}
                                  ref={this.discountCodeField}
                                >
                                  <Field
                                    className={'form-group__input' + (this.state.coupon.invalid ? ' invalid' : '')}
                                    type="text" name="discountCode"
                                  />
                                  <ErrorMessage className="form-group__error" name="discountCode" component="span" />
                                  <button
                                    className={(this.state.coupon.couponId ? 'btn btn--primary' : 'btn btn--secondary') + (isCouponLoading ? ' loading' : '')}
                                    disabled={isCouponLoading|isSubmitting}
                                    type="button"
                                    onClick={() => this.onSubmitDiscountCode(props.values.discountCode)}
                                  >
                                    {t('user.pricing.apply')}
                                  </button>
                                </div>
                                {this.state.coupon.couponId && (
                                  <div className="checkout__discount--message">
                                    <img src={CheckGreen} alt="Check Green" />
                                    <p>{t('user.pricing.discount_code_applied')}</p>
                                  </div>
                                )}
                                {this.state.coupon.invalid && (
                                  <div ref={this.discountErrorMessage} className="checkout__discount--message invalid">
                                    <img src={ErrorSign} alt="Error Sign" />
                                    <p>{t('user.pricing.discount_code_invalid')}</p>
                                  </div>
                                )}
                              </div>
                            </li>
                          )}
                          <li>
                            <div className="info-details__label checkout_label-info">
                              <label>{t('user.pricing.payment_sum')}</label>
                            </div>
                            <div className="info-details__info">
                              <p className="pacific-blue">{this.state.total}</p>
                            </div>
                          </li>
                        </ul>
                        <div className="checkout__order-subscription">
                          <button
                            className={`btn btn--primary ${isLoading || isSubmitting ? 'loading' : ''}`}
                            disabled={isLoading || isSubmitting || orderSummaryError}
                            type="submit"
                            onClick={() => {
                              const err = Object.keys(props.errors)
                              if (err.length) {
                                const input = document.querySelector(
                                  `input[name=${err[0]}]`
                                )
                                input.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'center',
                                  inline: 'start',
                                })
                              }
                            }}
                          >
                            {t('continue')}
                          </button>
                          {(paymentMethod === 'creditCard' && product.category !== 'signature') && (
                            <p>{t('user.pricing.checkout_info')}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          <div id="iframeSection">
            <Modal3DSConfirmation
              active={this.state.modal3DsConfirmation.active}
              src={this.state.modal3DsConfirmation.src}
              onCloseClick={this.on3dsModalClose}
              onModalActivityCompletion={this.onModalActivityCompletion}
            />
          </div>
        </div>
        <StatusMessages aboveBottomBar={true} />
      </main>
    )
  }
}

PricingCheckout.propTypes = {
  user: PropTypes.object,
  cart: PropTypes.array,
  clearCart: PropTypes.func,
  showStatusMessage: PropTypes.func,
  t: PropTypes.func,
  i18n: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  pushGTagAddPaymentInfo: PropTypes.func,
  pushGTagBeginCheckout: PropTypes.func,
  pushGTagPurchaseSuccess: PropTypes.func,
}