import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as documentService from '~/js/services/user/document'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import { withTranslation } from 'react-i18next'
import { updateFirstSignature } from '~/js/services/user/user'
import { PM_TYPE_ERROR, PM_TYPE_SUCCESS, postSigningInfoToParentDocument } from '../utils/postMessage'

const controlCodeTimeLimit = 150
const batchSize = 5

const withZealIdQrHtml = (ZealIDQRCodeComponent) => {
  class NewZealIDQRCodeComponent extends Component {
    constructor(props)
    {
      super(props)
      this.state = {
        zealIdAccessToken: undefined,
        zealIdSigningData: undefined,
        currentZealStage: 1,
        qrHashCalled: 0,
        signHashCalled: 0,
        modalZealIdDocumentSign: {
          active: false,
          type: 'authorize'
        },
        requestInitialized: false,
        selectedDocuments: [],
        currentDocumentIds: [],
        signingStarted: false,
        totalSigned: 0,
        signatureMissing: false,
      }
    }

    initializeZealIdSigning = () => {
      const { selectedZealDocIds, multiDocument } = this.props
      const { selectedDocuments, signingStarted } = this.state

      if (multiDocument) {
        let currentSelectedDocuments = selectedDocuments
        let removedItems = []
        if (!signingStarted) {
          removedItems = selectedZealDocIds.splice(0, batchSize)
          currentSelectedDocuments = selectedZealDocIds
        } else {
          if (currentSelectedDocuments.length) {
            removedItems = currentSelectedDocuments.splice(0, batchSize)
          } else {
            this.updateTemporarySigningStatus()
          }
        }

        this.setState({
          selectedDocuments: currentSelectedDocuments,
          currentDocumentIds: removedItems,
          signingStarted: true
        })
      }

      if (!this.state.requestInitialized) {
        this.setState({ requestInitialized: true })
        documentService
          .initializeZealIdSigning(this.props.temporarySignLink ? this.props.temporarySignLink : null)
          .then(data => {
            this.showModalZealIdDocumentSign(data.html, data.type, this.hideModalZealIdDocumentSign)
            this.setState({ zealIdAccessToken: data.accessToken, requestInitialized: false })
          })
      }
    }

    showModalZealIdDocumentSign = (qrHtml, type, closeCallback) => {
      this.setState({
        modalZealIdDocumentSign: {
          active: true,
          qrHtml,
          type,
          closeCallback,
        }
      })
    }

    hideModalZealIdDocumentSign = () => {
      this.setState({
        modalZealIdDocumentSign: {
          active: false,
          qrHtml: '',
          type: '',
        },
        currentZealStage: 1,
        qrHashCalled: 0,
        signHashCalled: 0,
      })
    }

    onZealIdCodeReceive = (code) => {
      if (this.state.currentZealStage === 1 && this.state.qrHashCalled === 0) {
        this.showQrCodeForHash(code)
      } else if (this.state.currentZealStage === 2 && this.state.signHashCalled === 0) {
        this.finalizeZealDocumentSign(code)
      }
    }

    getDocumentId = () => {
      return this.props.documentId ? this.props.documentId : this.props.match.params.documentId
    }

    showQrCodeForHash = (code) => {
      this.setState({ qrHashCalled: 1 })
      this.getQrCodeForHashes(code)
        .then(data => {
          this.setState({ currentZealStage: 2, zealIdSigningData: data })
          this.showModalZealIdDocumentSign(data.html, data.type, this.hideModalZealIdDocumentSign)
        })
        .catch(err => {
          this.props.showStatusMessage(SM_TYPE_ERROR, err.message)
          postSigningInfoToParentDocument(PM_TYPE_ERROR, err.message)
          this.hideModalZealIdDocumentSign()
          this.reloadFullPage()
        })
    }

    getQrCodeForHashes = (code) => {
      const { temporarySignLink, multiDocument } = this.props
      const { zealIdAccessToken, currentDocumentIds } = this.state

      if (multiDocument) {
        return documentService.getQrCodeForHashes(code, currentDocumentIds, zealIdAccessToken, temporarySignLink)
      }

      return documentService.getQrCodeForHash(code, this.getDocumentId(), zealIdAccessToken, temporarySignLink)
    }

    finalizeZealDocumentSign = (code) => {
      this.setState({ currentZealStage: 1, signHashCalled: 1 })
      const { temporarySignLink, multiDocument, showStatusMessage } = this.props
      const { documentId } = this.state.zealIdSigningData

      this.finalizeDocumentsSigningProcess(code, multiDocument, documentId)
        .then((data) => {
          if (multiDocument) {
            this.setState({ signatureMissing: data.signatureMissing })
            if (data.tokens.length > 0) {
              return this.pollSignStatusForBulk(data.tokens, temporarySignLink)
            }
          } else {
            return documentService.pollSignStatus(documentId, { token: data.token, authMethod: 'zealid' }, controlCodeTimeLimit, temporarySignLink).promise
          }
        })
        .then(() => {
          if (!this.state.signatureMissing) {
            if (multiDocument && this.state.selectedDocuments.length > 0) {
              this.setState({
                currentZealStage: 1,
                qrHashCalled: 0,
                requestInitialized: false,
                signHashCalled: 0,
              })

              this.initializeZealIdSigning()
            } else {
              if (temporarySignLink) {
                this.updateTemporarySigningStatus(temporarySignLink)
              } else {
                this.showSuccessMessage()
              }
            }
          } else {
            const fnTranslate = this.translatedMessage('user.document.temporary_usb_bulk_documents_signed', { documentSigned: this.state.totalSigned + this.props.alreadySigned })
            showStatusMessage(SM_TYPE_ERROR, fnTranslate())
            postSigningInfoToParentDocument(PM_TYPE_ERROR, fnTranslate('en'))

            this.hideModalZealIdDocumentSign()
            this.reloadFullPage()
          }
        })
        .catch(err => {
          this.props.showStatusMessage(SM_TYPE_ERROR, err.message)
          postSigningInfoToParentDocument(PM_TYPE_ERROR, err.message)
          this.hideModalZealIdDocumentSign()
          this.reloadFullPage()
        })
    }

    finalizeDocumentsSigningProcess = (code, multiDocument, documentId) => {
      const { temporarySignLink } = this.props
      const { currentDocumentIds } = this.state

      const { hermesToken, credentialId, hashToSignArray, zealIds, hashToSign, certificates, zealId } = this.state.zealIdSigningData
      if (multiDocument) {
        return documentService.finalizeDocumentsSign({
          code: code,
          hermesToken: hermesToken,
          credentialId: credentialId,
          hashToSignArray: hashToSignArray,
          documentIds: currentDocumentIds,
          zealIds: zealIds,
          temporarySignLink: temporarySignLink
        })
      }

      return documentService.finalizeDocumentSign({
        code: code,
        hermesToken: hermesToken,
        credentialId: credentialId,
        hashToSign: [hashToSign],
        certificate: certificates[0],
        documentId: documentId,
        zealId: zealId,
        temporarySignLink: temporarySignLink
      })
    }

    pollSignStatusForBulk(tokens, temporarySignLink)
    {
      let { totalSigned } = this.state

      return Promise.all(tokens.map((t) => {
        return documentService.pollSignStatus(
          t.documentId,
          { token: t.token, authMethod: 'zealid' },
          controlCodeTimeLimit,
          temporarySignLink
        ).promise.then(() => this.setState({ totalSigned: ++totalSigned }))
      }))
    }

    updateTemporarySigningStatus = (temporarySignLink) => {
      return documentService.updateTemporarySignStatus(temporarySignLink, { signed: true })
        .then(() => {
          this.showSuccessMessage()
        })
    }

    updateFirstSignatureStatus = () => {
      const { user, foremostSignButtonClick } = this.props
      if (user && user.firstSignature) {
        foremostSignButtonClick(user.email, user.phoneNumber)
        updateFirstSignature(false)
      }
    }

    showSuccessMessage = () => {
      const { showStatusMessage, multiDocument, totalZealDeadlineExpired, totalZealOtherType } = this.props
      this.hideModalZealIdDocumentSign()
      const fnTranslate = multiDocument
        ? this.translatedMessage('user.document.temporary_usb_documents_signed', { documentSigned: this.state.totalSigned + this.props.alreadySigned, deadlineExpired: totalZealDeadlineExpired, totalOtherType: totalZealOtherType })
        : this.translatedMessage('user.document.you_have_successfully_signed_this_document')
      showStatusMessage(SM_TYPE_SUCCESS, fnTranslate())
      postSigningInfoToParentDocument(PM_TYPE_SUCCESS, fnTranslate('en'))

      this.updateFirstSignatureStatus()
      this.reloadFullPage()
    }

    reloadFullPage = () => {
      setTimeout(() => {
        window.location.reload()
      }, 2500)
    }

    translatedMessage(transKey, params) {
      const { t } = this.props
      return lng => t(transKey, { ...params, lng })
    }

    render()
    {
      return <ZealIDQRCodeComponent
        {...this.props}
        initializeZealIdSigning={this.initializeZealIdSigning}
        modalZealIdDocumentSign={this.state.modalZealIdDocumentSign}
        onZealIdCodeReceive={this.onZealIdCodeReceive}
        updateFirstSignatureStatus={this.updateFirstSignatureStatus}
        signatureMissing={this.state.signatureMissing}
      />
    }
  }

  NewZealIDQRCodeComponent.propTypes = {
    t: PropTypes.func,
    showStatusMessage: PropTypes.func,
    documentId: PropTypes.string,
    temporarySignLink: PropTypes.string,
    match: PropTypes.object,
    user: PropTypes.object,
    foremostSignButtonClick: PropTypes.func,
    selectedZealDocIds: PropTypes.array,
    multiDocument: PropTypes.bool,
    totalZealDeadlineExpired: PropTypes.number,
    totalZealOtherType: PropTypes.number,
    documents: PropTypes.array,
    alreadySigned: PropTypes.number,
  }

  return withTranslation()(NewZealIDQRCodeComponent)
}

export default withZealIdQrHtml
