// @flow

import * as React from 'react'
import ControlButtons, { ControlButton } from '../../ControlButtons'
import { useTranslation } from 'react-i18next'

type File = {
  id: string | number,
  filename: string,
  isMain: boolean,
}
type Props = {
  files: File[],
  onDownloadClick: (File) => void,
  version?: string,
  showMainFile?: boolean,
}

export default function FilesViewer({ files, onDownloadClick, version, showMainFile }: Props): React.Node {
  const { t } = useTranslation()

  return (
    <ul className="preview__document-files" data-version={version}>
      {files.map(file => (
        file.filename !== '' && (
          <li key={`docfile-${file.id}`} className="preview__document-file">
            <span className="strong-text">{file.filename}</span>
            <div className="status-and-controls">
              {showMainFile && file.isMain && <div className="status">{t('common.main')}</div>}
              <ControlButtons>
                <ControlButton
                  type="download"
                  title={t('common.download')}
                  showLabel={false}
                  showTooltip={true}
                  onClick={() => onDownloadClick(file)}
                />
              </ControlButtons>
            </div>
          </li>
        )
      ))}
    </ul>
  )
}